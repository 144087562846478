import { Controller } from "@hotwired/stimulus";
import * as cookies from "../utilities/cookies.js";

/**
  * General-purpose modal controller.
  * Connects to data-controller="modal"
  *
  * properties:
  * @property {string} today - Today's date in the format "Mon Jan 01 2021".
  * @property {string} [cookie] - The name of the cookie to set when the modal is closed. Pass in the cookie name from the view as, e.g. local_assigns[:cookie] = "homepage_popup_dismissed".
  *
  * methods:
  * @function connect - Connects to data-controller="modal"
  * @function setCookie - When the modal is closed, sets a cookie with today's date. Only when cookie is specified.
  * @function back - Reloads the wrapper element.
  * @function close - Removes the modal from the DOM and sets a cookie with today's date
  *   @param {boolean} [with_cookie=true] - Whether or not to set the cookie. Defaults to true.
  * @function hasCurrentCookie - Checks if the cookie exists and is set to today's date.
*/
export default class extends Controller {
  static targets = ["closeButton"];
  today = new Date().toDateString();
  cookie = this.closeButtonTarget.dataset.cookie;

  /**
    * @property {HTMLElement} frame - The wrapper element that contains the modal.
    * @property {HTMLElement} backButton - The back button element.
    * @property {HTMLElement} internalFrameLinks - The links inside the modal that should not open in a new tab.
   */
  connect() {
    this.frame = this.element.parentElement; // This needs to be initialized first so that we can remove everything from the DOM if the modal isn't needed.
    document.body.classList.add("modal-open");

    if ( !this.hasCurrentCookie() ) {
      this.element.classList.remove("hidden");
    } else {
      this.close(false);
    }

    // The below code is not related to the cookie functionality, but is necessary for the modal to work.
    this.backButton = this.element.querySelector(".modal__back");
    this.internalFrameLinks = this.element.querySelectorAll("a[data-turbo-frame='_self']");

    if (this.backButton && this.internalFrameLinks) {
      this.internalFrameLinks.forEach((link) => {
        link.addEventListener("click", (e) => {
          this.backButton.classList.remove("js-hidden");
        });
      });
    }
  }

  setCookie() {
    if (this.cookie) {
      cookies.setCookie(this.cookie, this.today);
    }
  }

  back() {
    this.frame.reload();
  }

  close(with_cookie = true) {
    this.frame.removeAttribute("src");
    this.element.remove();
    document.body.classList.remove("modal-open");
    if (with_cookie) {
      this.setCookie();
    }
  }

  hasCurrentCookie() {
    return this.cookie && cookies.getCookie(this.cookie) === this.today;
  }
}
