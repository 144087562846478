import app from "../lockdown_app";

export class UpcomingLectureModule {
  constructor(element) {
    this.element = element;
    this.dateSpan = element.querySelector(".js-ulm-countdown-date");
    this.countdownDate = Number(this.dateSpan.dataset.date);
    this.countdown();
  }

  calculateTime() {
    const future = this.countdownDate;
    const now = new Date();

    let s = Math.floor((future - (now)) / 1000);
    let m = Math.floor(s / 60);
    let h = Math.floor(m / 60);
    let d = Math.floor(h / 24);

    h = h - (d * 24);
    m = m - (d * 24 * 60) - (h * 60);
    s = s - (d * 24 * 60 * 60) - (h * 60 * 60) - (m * 60);

    return `${d}d ${h}h ${m}m ${s}s`;
  }

  setTimeText() {
    const time = this.calculateTime();
    this.dateSpan.innerText = time;
  }

  countdown() {
    this.setTimeText();
    setInterval(() => this.setTimeText(), 1000);
  }
}

export const upcomingLectureModules = {
  current: [],
}

export const init = () => {
  app.addEventListener("page-load", (e) => {
    upcomingLectureModules.current = [
      ...e.target.querySelectorAll(".js-upcoming-lecture-module"),
    ].map((instance) => new UpcomingLectureModule(instance));
  })
}
