import app from "../lockdown_app";
import { Carousel } from "../components/carousel";

export class LecturePlaylistModule {
  constructor(element) {
    this.element = element;

    const carousel = new Carousel(this.element, {
      adaptiveHeight: true,
      cellAlign: "left",
      pageDots: false,
      prevNextButtons: false,
    });
  }
}

export const lecturePlaylistModules = {
  current: [],
};

export const init = () => {
  app.addEventListener("page-load", (e) => {
    lecturePlaylistModules.current = [
      ...e.target.querySelectorAll(".js-lecture-playlist-module"),
    ].map((instance) => new LecturePlaylistModule(instance));
  });
};
