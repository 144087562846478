import app from "../lockdown_app";

export class PanelFader {
  constructor(element) {
    this.element = element;
    this.frontPanel = element.querySelector(".js-panel-fader-front-panel");
    this.backPanel = element.querySelector(".js-panel-fader-back-panel");
    this.accessibleAnnouncer = element.querySelector(".js-accessible-announcer");
    this.videoThumbnailLink = element.querySelector(".js-video-thumbnail-link");

    this.frontPanel.addEventListener("click", () => {
      this.handleFade();
    });

    this.makeAccessible();
  }

  handleFade() {
    if (app.state.breakpoint == "xs") {
      return;
    }

    this.frontPanel.addEventListener("transitionend", () => {
      this.backPanel.ariaHidden = "false";
      this.backPanel.style.opacity = 1;
      this.frontPanel.style.zIndex = -1;
      this.frontPanel.style.visibility = "hidden";

      this.accessibleAnnouncer.textContent = "The front title panel has been removed. Underlying content, such as a carousel or video, is now on screen."
      this.backPanel.focus();

      // Activate email wall modal
      if (this.videoThumbnailLink) {
        setTimeout(() => {
          this.videoThumbnailLink.click();
        }, 700);
      }
    });

    this.frontPanel.ariaHidden = "true";
    this.frontPanel.style.opacity = 0;
  }

  makeAccessible() {
    this.element.ariaLive = "polite";
    this.backPanel.ariaHidden = "true";
  }
}

export const panelFaders = {
  current: [],
};

export const init = () => {
  app.addEventListener("page-load", (e) => {
    panelFaders.current = [
      ...e.target.querySelectorAll(".js-panel-fader"),
    ].map((instance) => new PanelFader(instance));
  })
}
