import Flickity from "flickity";

export class Carousel {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.flickity;
    this.currentSlide;
    this.previousSlide;

    // Required elements
    this.carouselElement = this.element.querySelector(".js-carousel");
    this.accessibleAnnouncer = this.element.querySelector(".js-accessible-announcer");

    // Optional elements
    this.buttons = this.element.querySelector(".js-carousel-buttons");
    this.prevButton = this.element.querySelector(".js-carousel-button-prev");
    this.nextButton = this.element.querySelector(".js-carousel-button-next");
    this.caption = this.element.querySelector(".js-carousel-caption");

    // Initialize
    this.makeCarousel();
    this.makeAccessible();
  }

  handleChange(slideIndex) {
    this.previousSlide = this.currentSlide;
    this.currentSlide = this.flickity.selectedElement;

    // Announce slide change
    this.accessibleAnnouncer.textContent = `You are on slide ${slideIndex + 1} of ${this.flickity.cells.length}.`

    // Change aria-hidden state on slide change
    this.previousSlide.ariaHidden = "true";
    this.currentSlide.ariaHidden = "false";

    // Update caption
    if (this.caption) {
      this.caption.innerText = this.flickity.selectedElement.dataset.caption;
    }
  }

  makeCarousel() {
    this.flickity = new Flickity(this.carouselElement, this.options);

    this.currentSlide = this.flickity.selectedElement;

    this.flickity.on("change", (slideIndex) => {
      this.handleChange(slideIndex);
    });

    if (this.buttons) {
      this.prevButton.addEventListener("click", () => {
        this.flickity.previous();
      });

      this.nextButton.addEventListener("click", () => {
        this.flickity.next();
      });
    }
  }

  makeAccessible() {
    // Allow all announcements on parent element
    this.element.ariaLive = "polite";

    // Non-focused slides should be aria-hidden
    this.flickity.cells.forEach((cell) => {
      cell.element.ariaHidden = "true";
    });

    // Current element should not be aria-hidden
    this.flickity.selectedElement.ariaHidden = "false";
  }
}
