import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggler"];
  static values = { open: Boolean };

  toggle() {
    this.openValue ? this.element.classList.remove("accordion--open") : this.element.classList.add("accordion--open");
    this.togglerTarget.attributes.checked ? this.togglerTarget.removeAttribute("checked") : this.togglerTarget.setAttribute("checked", "true");
    this.openValue = this.openValue === true ? false : true;
  }
}
