import Flickity from "flickity";
require("flickity-as-nav-for");

export class NavCarousel {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.flickity;

    // Required elements
    this.carouselElement = this.element.querySelector(".js-carousel");
    this.navCarouselElement = this.element.querySelector(".js-nav-carousel");

    // Initialize
    this.makeCarousel();
  }

  makeCarousel() {
    this.options.asNavFor = this.carouselElement;
    this.flickity = new Flickity(this.navCarouselElement, this.options);
  }

  makeAccessible() {
    // NOTE: asNavFor carousels are decorative/repetitive in nature and do not
    // need to be made accessible, other than specifying aria-hidden="true".
  }
}
