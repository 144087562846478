import app from "../lockdown_app";
import { Carousel } from "../components/carousel";
import { NavCarousel } from "../components/nav_carousel";

export class CarouselModule {
  constructor(element) {
    this.element = element;

    const carousel = new Carousel(this.element, {
      cellAlign: "left",
      contain: true,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
    });

    const navCarousel = new NavCarousel(this.element, {
      cellAlign: "left",
      contain: true,
      draggable: false,
      pageDots: false,
      prevNextButtons: false,
    });
  }
}

export const carouselModules = {
  current: [],
};

export const init = () => {
  app.addEventListener("page-load", (e) => {
    carouselModules.current = [
      ...e.target.querySelectorAll(".js-carousel-module"),
    ].map((instance) => new CarouselModule(instance));
  });
};
