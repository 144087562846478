/**
 * Retrieves the value of a cookie with the specified name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {string|null} The value of the cookie, or null if the cookie is not found.
 */
export const getCookie = (name) => {
  const encodedName = encodeURIComponent(name);
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(encodedName + "=")) {
      const value = decodeURIComponent(cookie.substring(encodedName.length + 1));
      return value;
    }
  }

  return null;
};

/**
 * Set a new cookie with the given name, value, and expiration in days.
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to set for the cookie. If string, assume that it is a date string.
 */
export const setCookie = (name, value) => {
  const encodedName = encodeURIComponent(name);
  const encodedValue = encodeURIComponent(value);

  document.cookie = `${encodedName}=${encodedValue}; path=/; max-age=31536000;`;
};

