// Import

// Stimulus
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

// Components
import * as calendarButton from "./components/calendar_button";
import * as newsletterSignupForm from "./components/newsletter_signup_form";
import * as panelFader from "./components/panel_fader";

// Pages

// BEGIN MAGIC_MODULE_IMPORT
import * as carouselModule from "./magic_modules/carousel_module";
import * as lecturePlaylistModule from "./magic_modules/lecture_playlist_module";
import * as upcomingLectureModule from "./magic_modules/upcoming_lecture_module";
// END MAGIC_MODULE_IMPORT

// Initialize

// Stimulus
window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

// Components
calendarButton.init();
newsletterSignupForm.init();
panelFader.init();

// BEGIN MAGIC_MODULE_INIT
carouselModule.init();
lecturePlaylistModule.init();
upcomingLectureModule.init();
// END MAGIC_MODULE_INIT
