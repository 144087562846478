import app from "../lockdown_app";
import { atcb_action, atcb_init } from 'add-to-calendar-button';

export class CalendarButton {
  constructor(element) {
    this.element = element;
    atcb_init();

    if (this.element.querySelector(".atcb-text")) {
      this.element.querySelector(".atcb-text").innerText = "Add to calendar";
    }
  }
}

export const calendarButtons = {
  current: [],
};

export const init = () => {
  app.addEventListener("page-load", (e) => {
    calendarButtons.current = [
      ...e.target.querySelectorAll(".js-calendar-button"),
    ].map((instance) => new CalendarButton(instance));
  })
}
