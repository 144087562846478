import { Controller } from "@hotwired/stimulus";
import * as cookies from "../utilities/cookies.js";

/**
 * This controller activates the homepage animation after a delay.
 * Connects to data-controller="homepage-splash"
*/
export default class extends Controller {
  static targets = ["wrapper"];
  today = new Date().toDateString();

  /**
    * The animation should only ever run once per day, after the homepage popup has been dismissed.
    * Thus, if the "homepage_popup_dismissed" cookie is not set to today's date, the animation should run.
  */
  connect() {
    // If homepage modal doesn't exist, we aren't using it.
    // Trigger move and set cookie so animation doesn't run again.
    if (!document.querySelector(".modal")) {
      if (cookies.getCookie("homepage_popup_dismissed") !== this.today) {
        this.wrapperTarget.classList.remove("homepage-has-moved");
        this.move();
        cookies.setCookie("homepage_popup_dismissed", this.today);
      }
    }
    else {
      if (cookies.getCookie("homepage_popup_dismissed") !== this.today) {
        this.wrapperTarget.classList.remove("homepage-has-moved");
      }
    }
  }

  /**
    * This is activated by the homepage popup's "close" button, but can be called from anywhere.
    * That close button also sets a cookie with today's date, so the animation will not run again until tomorrow.
  */
  move() {
    setTimeout(() => {
      this.wrapperTarget.classList.add("homepage-has-moved");
    }, 1200);
  }
}
